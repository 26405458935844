*{
  margin: 0px;
  padding: 0px;
}

.custom-nav{
  box-shadow: 0 2px 15px rgb(0 0 0 / 8%);
  background-color: #fff;
  border-bottom-color: #c9c9c9;
  border-bottom-width: 1px;
  border-bottom-style: solid;
} 

.navbar-brand > div.logo{
  border: 1px solid #fff;
  color:#fff !important;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  cursor: pointer;
}

.NavLink{
  color:#000 !important;
}