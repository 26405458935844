*{
  margin: 0;
  padding: 0;
}

.footer {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 56px;
  border-top: 1px solid #cacaca;
}