*{
  margin: 0;
  padding: 0
}

/* Left Section */

.home_left-section {
  border-right: 1px solid #cacaca;
  height: 100vh; 
  padding: 0px;
  overflow: scroll;
}

.home_left-section::-webkit-scrollbar {
  display: none;
}

.searchbar-container {
  position: relative;
  background-color: #f7f7fa;
  padding: 32px 16px;
  border-radius: 6px;
}

.searchbar {
  box-shadow: 2px 5px 8px #f1f1f1;
  width: 100%;
}

.searchResults {
  max-height: 200px;
  background-color: #fff;
  border: 1px solid #cacaca;
  border-top: 0;
  padding: 8px;
  position: absolute;
  width: 100;
  width: calc(100% - 32px);
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  border-top: 0px;
  z-index: 99;
  overflow-y: scroll;

}

/* Right Section */

.home_right-section {
  height: 100vh; 
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: auto;
}

.channel_details {
  background-color: #000;
  padding: 16px;
  width: '100%';
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  display: flex;

}

.chat-window {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: scroll;
  align-items: stretch;
  padding: 16px;
}

.send-message-bar {
  margin-bottom: 0;
}


.message-container-right {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  margin-bottom: 24px;
}

.message-container-left {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 24px;
}

.right-message {
  margin-right: 12px;
  max-width: 70%;
}

.left-message {
  margin-left: 12px;
  max-width: 70%;
}


.chat-bubble-right {
  background-color: #1a233b;
  padding: 4px;
  padding: 6px 14px;
  border-radius: 8px;
  word-break: break-all
}

.chat-bubble-left {
  background-color: #f5f5f5;
  padding: 4px;
  padding: 6px 14px;
  border-radius: 8px;
  word-break: break-all;
}

.message-sender-left {
  font-size: 10px;
  margin-bottom: 0px;
  color: #313131;
  text-align: left;
}

.message-sender-right {
  font-size: 10px;
  margin-bottom: 0px;
  color: #313131;
  text-align: right;
}

.message-content-left {
  color: #000;
  margin-bottom: 6px;
}

.message-content-right {
  margin-bottom: 6px;
  color: #fff
}

.message-status-right {
  font-size: 10px;
  color: #313131;
  margin-bottom: 0px;
  text-align: right;
}

.message-status-left {
  font-size: 10px;
  color: #313131;
  margin-bottom: 0px;
  text-align: left;
}

.message-time-right {
  color: #fff;
  text-align: right;
  font-size: 10px;
  margin-bottom: 0px;
}

.message-time-left {
  color: #000;
  text-align: right;
  font-size: 10px;
  margin-bottom: 0px;
}

.send-message-bar .form-control{
  border-radius: 0;
  border: 0px;
  border-top: 1px solid #cacaca;
}

.form-control:hover,
.form-control:active,
.form-control:focus{
  outline: none;
  box-shadow: none
}

.send-message-bar button{
  border-radius: 0px;
  outline: none;
  box-shadow: 0
}

.btn:active,
.btn:focus {
  box-shadow: none;
  outline: none;
}