*{
  margin: 0px;
  padding: 0px;
}

.welcome-screen {
  display: flex;
  flex: 1;
  height: 100vh;
  width: 100vw;
  justify-content: center;
  align-items: center;
  background: url(https://plantapp.io/images/patterns/header@1x.png);
  background-position: center;
  background-size: cover;
}

.welcome-text {
  font-size: 32px;
  font-weight: 700;
  border: 5px solid #000;
  padding: 0px 14px;
}